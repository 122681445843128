<template>
  <section class="item-types">
    <div class="row text_center">
      <div class="col-12">
        <div class="divider">
          <div class="divider">
            <span class="divider__text text_green">Midrails and Mullions</span>
          </div>
        </div>
      </div>

      <div class="col-12 text_center">
        <h4>Size and configuration of this item require either Midrail or Mullion.<br>Please choose below</h4>
      </div>

      <div
        v-for="(item, index) in selection"
        :key="item.id"
        class="col-6"
      >
        <div
          class="box"
          @click="toOptions(index, item)"
        >
          <p class="box__imgWrap">
            <img
              class="box__img"
              :src="item.icon"
              :alt="item.name"
            >
          </p>
          <p
            class="text_default box__title"
          >
            {{ item.name }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

import {mapState, mapGetters} from 'vuex'

export default {
    name: 'MidrailMullion',

    components: {},
    data() {
        return {
        }
    },

    computed: {

        selection(){
            let options = this.$store.getters['orderPosition/orderPosition'].options.default

            let r = []

            _.each(options, (i) => {
                if (i.code == 'MIDR' || i.code == 'MULL')
                    r.push(i)
            })

            return r
        },

    },

    created() {
    },

    beforeDestroy() {
    },

    methods: {
        toOptions(index, item) {
            this.$store.commit('orderPosition/resolveMidrailMullion', item.code)
        },
    },
}
</script>

<style scoped lang="scss">

</style>
